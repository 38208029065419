<template>
  <div class="result">
    <img src="@/assets/finish.png" alt="">
    <h3>申请成功</h3>
    <p>管理员审核后将会自动以微信红包的形式发放给您！请耐心等待</p>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {
  }
}
</script>


<style scoped>
.result{
  padding:10px;
  text-align: center;
}

img{
  width:120px;
  margin: 50px 0 30px;
}

p{
  color: #333;
}
</style>
